import React from 'react'

function Hero() {
  return (
    <>
    <section id="hero" className="d-flex align-items-center">
    <div className="container">
      <h1  style={{
        display:"none"
      }}>
        growvive
        GrowVive 
        Growvive web development agency
        best web development agency
        web development agency
        web development agency in india
        best web development agency in india
        web development agency in hyderabad
        best web development agency in hyderabad
        web development agency in telangana
        best web development agency in telangana
        web development agency in india
        best web development agency in india
        web development agency in hyderabad
        growvive agency
        growvive web development agency
        

      </h1>
      <div className="row gy-4">
        <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1>
          Elevate your online presence with <span style={{color:"#F7941B",fontWeight:"bolder"}}>Growvive</span>
          </h1>
          <h2>
          we specialize in translating your business aspirations into a powerful online presence through expertly crafted websites
          </h2>
          <div>
            <a href="#about" className="btn-get-started scrollto">
              Get Started
            </a>
          </div>
        </div>
        <div className="col-lg-6 order-1 order-lg-2 hero-img">
          <img
            src="assets/img/hero-img.svg"
            className="img-fluid animated"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default Hero
