import React from "react";

function Porfolio() {
  return (
    <section id="portfolio" className="portfolio">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Portfolio</h2>
          <p>
            Check out our latest portfolio of websites
          </p>
        </div>
        <div className="row" data-aos="fade-up" data-aos-delay={100}>
          {/* <div className="col-lg-12">
            <ul id="portfolio-flters">
              <li data-filter="*" className="filter-active">
                All
              </li>
              <li data-filter=".filter-app">App</li>
              <li data-filter=".filter-card">Card</li>
              <li data-filter=".filter-web">Web</li>
            </ul>
          </div> */}
        </div>
        <div
          className="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="col-lg-6 col-md-6 portfolio-item filter-app">
            <div className="portfolio-wrap">
              <img
                src="assets/img/portfolio/1.png"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-links">
                <a
                  href="assets/img/portfolio/1.png"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox"
                  title="App 1"
                >
                  <i className="bi bi-plus" />
                </a>
                <a href="https://weiw.in" title="More Details" target="_blank">
                  <i className="bi bi-link" />
                </a>
              </div>
              <div className="portfolio-info">
                <h4>Weiw, India</h4>
                <p>weiw.in</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img
                src="assets/img/portfolio/2.png"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-links">
                <a
                  href="assets/img/portfolio/2.png"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox"
                  title="Web 3"
                >
                  <i className="bi bi-plus" />
                </a>
                <a
                  href="https://intgateway.com/"
                  title="More Details"
                  target="_blank"
                >
                  <i className="bi bi-link" />
                </a>
              </div>
              <div className="portfolio-info">
                <h4>International Gateway Limited, UK</h4>
                <p>intgateway.com</p>
              </div>
            </div>
          </div>
          {/* <div
          className="row portfolio-container"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <div className="col-lg-6 col-md-6 portfolio-item filter-web">
            <div className="portfolio-wrap">
              <img
                src="assets/img/portfolio/5.png"
                className="img-fluid"
                alt=""
              />
              <div className="portfolio-links">
                <a
                  href="assets/img/portfolio/5.png"
                  data-gallery="portfolioGallery"
                  className="portfolio-lightbox"
                  title="Web 2"
                >
                  <i className="bi bi-plus" />
                </a>
                <a href="portfolio-details.html" title="More Details">
                  <i className="bi bi-link" />
                </a>
              </div>
              <div className="portfolio-info">
                <h4>Web 2</h4>
                <p>Web</p>
              </div>
            </div>
          </div>
          </div> */}
        </div>
        <div
            className="row portfolio-container "
            data-aos="fade-up"
            data-aos-delay={200}
          >
            <div className="col-lg-6 col-md-6 portfolio-item filter-app">
              <div className="portfolio-wrap">
                <img
                  src="assets/img/portfolio/3.png"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-links">
                  <a
                    href="assets/img/portfolio/3.png"
                    data-gallery="portfolioGallery"
                    className="portfolio-lightbox"
                    title="App 2"
                  >
                    <i className="bi bi-plus" />
                  </a>
                  <a
                    href="https://thebonkurgroup.com"
                    title="More Details"
                    target="_blank"
                  >
                    <i className="bi bi-link" />
                  </a>
                </div>
                <div className="portfolio-info">
                  <h4>Bonkur Group, UK</h4>
                  <p>thebonkurgroup.com</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 portfolio-item filter-card">
            <div className="portfolio-wrap">
                <img
                  src="assets/img/portfolio/5.png"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-links">
                  <a
                    href="assets/img/portfolio/5.png"
                    data-gallery="portfolioGallery"
                    className="portfolio-lightbox"
                    title="Card 2"
                  >
                    <i className="bi bi-plus" />
                  </a>
                  <a
                    href="https://spaczcubearchitects.com/"
                    title="More Details"
                    target="_blank"
                  >
                    <i className="bi bi-link" />
                  </a>
                </div>
                <div className="portfolio-info">
                  <h4>Spacz Cube Architects, India</h4>
                  <p>spaczcubearchitects.com</p>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div
            className="row portfolio-container"
            data-aos="fade-up"
            data-aos-delay={200}
            style={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center"

            }}
          >
            <div className="col-lg-6 col-md-6 portfolio-item filter-app">
            <div className="portfolio-wrap">
                <img
                  src="assets/img/portfolio/4.png"
                  className="img-fluid"
                  alt=""
                />
                <div className="portfolio-links">
                  <a
                    href="assets/img/portfolio/4.png"
                    data-gallery="portfolioGallery"
                    className="portfolio-lightbox"
                    title="Card 2"
                  >
                    <i className="bi bi-plus" />
                  </a>
                  <a
                    href="https://vcsscanada.com/"
                    title="More Details"
                    target="_blank"
                  >
                    <i className="bi bi-link" />
                  </a>
                </div>
                <div className="portfolio-info">
                  <h4>VCSS, Canada</h4>
                  <p>vcsscanada.com</p>
                </div>
              </div>
          </div>
      </div>
    </section>
  );
}

export default Porfolio;
