import React from 'react'

function Team() {
  return (
    <section id="team" className="team">
    <div className="container">
      <div className="section-title" data-aos="fade-up">
        <h2>Team</h2>
        <p>
          Founder and CEO
        </p>
      </div>
      <div className="row" 
        data-aos="fade-up"
        data-aos-delay={100}
        style={{
          display:"flex",
          justifyContent:"center",
        }} 
      >
        <div className="col-xl-3 col-lg-3 col-md-6">
          <img src="assets/img/team/team-1.png" className="img-fluid" alt="" />
        </div>
        <div className="col-xl-8 col-lg-8 col-md-6 mt-1">
          <h3>
            Harshavardhan Bammidi
          </h3>
          <h6>
            Founder and CEO, Growvive | Upcoming Software Engineer @ PayPal
          </h6>
          <p>
          Passionate about web development, I founded this agency to craft exceptional digital experiences and empower businesses for online success. My internship at PayPal was foundational, and now, with proficiency in a Web-centric tech stack including ReactJs, AngularJs, WordPress, Shopify (CMS), and more, I bring a comprehensive understanding of the digital landscape. Beyond daily operations, my commitment to excellence is showcased through victories at hackathons like Smart India Hackathon and UNESCO India Africa Hackathon. This dual dedication—to web development artistry and client success—propels our agency as a catalyst for transformative online growth.
          </p>
          <p
          >

              <a href="https://www.linkedin.com/in/harshavardhan-bammidi/" target="_blank" rel="noreferrer">
                <i className="bi bi-linkedin" style={{
                  color:"#F7941B",
                  fontSize:"20px",
                  padding:"5px"

                }}/>
              </a>
              {" "}
              <a href="https://github.com/harshavb08" target="_blank" rel="noreferrer">
                <i className="bi bi-github" style={{
                  color:"#F7941B",
                  fontSize:"20px",
                  padding:"5px"

                }}/>
              </a>
              {" "}<a href="https://harshaz.live/" target="_blank" rel="noreferrer">
                <i className="bi bi-globe" style={{
                  color:"#F7941B",
                  fontSize:"20px",
                  padding:"5px"

                }}/>
              </a>
              {" "}<a href="https://www.youtube.com/@HarshavardhanBammidi" target="_blank" rel="noreferrer">
                <i className="bi bi-youtube" style={{
                  color:"#F7941B",
                  fontSize:"20px",
                  padding:"5px",
                }}/>
              </a>
          </p>
        </div>


        {/* <div
          className="col-xl-3 col-lg-4 col-md-6"
          data-aos="zoom-in"
          data-aos-delay={100}
        >
          <div className="member">
            <img
              src="assets/img/team/team-1.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="member-info">
              <div className="member-info-content">
                <h4>Walter White</h4>
                <span>Chief Executive Officer</span>
              </div>
              <div className="social">
                <a href="">
                  <i className="bi bi-twitter" />
                </a>
                <a href="">
                  <i className="bi bi-facebook" />
                </a>
                <a href="">
                  <i className="bi bi-instagram" />
                </a>
                <a href="">
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div
          className="col-xl-3 col-lg-4 col-md-6"
          data-aos="zoom-in"
          data-aos-delay={200}
        >
          <div className="member">
            <img
              src="assets/img/team/team-2.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="member-info">
              <div className="member-info-content">
                <h4>Sarah Jhonson</h4>
                <span>Product Manager</span>
              </div>
              <div className="social">
                <a href="">
                  <i className="bi bi-twitter" />
                </a>
                <a href="">
                  <i className="bi bi-facebook" />
                </a>
                <a href="">
                  <i className="bi bi-instagram" />
                </a>
                <a href="">
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div
          className="col-xl-3 col-lg-4 col-md-6"
          data-aos="zoom-in"
          data-aos-delay={300}
        >
          <div className="member">
            <img
              src="assets/img/team/team-3.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="member-info">
              <div className="member-info-content">
                <h4>William Anderson</h4>
                <span>CTO</span>
              </div>
              <div className="social">
                <a href="">
                  <i className="bi bi-twitter" />
                </a>
                <a href="">
                  <i className="bi bi-facebook" />
                </a>
                <a href="">
                  <i className="bi bi-instagram" />
                </a>
                <a href="">
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div
          className="col-xl-3 col-lg-4 col-md-6"
          data-aos="zoom-in"
          data-aos-delay={400}
        >
          <div className="member">
            <img
              src="assets/img/team/team-4.jpg"
              className="img-fluid"
              alt=""
            />
            <div className="member-info">
              <div className="member-info-content">
                <h4>Amanda Jepson</h4>
                <span>Accountant</span>
              </div>
              <div className="social">
                <a href="">
                  <i className="bi bi-twitter" />
                </a>
                <a href="">
                  <i className="bi bi-facebook" />
                </a>
                <a href="">
                  <i className="bi bi-instagram" />
                </a>
                <a href="">
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  </section>
  )
}

export default Team
