import React from 'react'

function FAQs() {
  return (
    <section id="faq" className="faq section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>F.A.Q</h2>
          <p>Frequently Asked Questions</p>
        </div>
        <ul className="faq-list" data-aos="fade-up" data-aos-delay={100}>
          <li>
            <div
              data-bs-toggle="collapse"
              className="collapsed question"
              href="#faq1"
            >
              What services does your web development agency provide?{" "}
              <i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </div>
            <div id="faq1" className="collapse" data-bs-parent=".faq-list">
              <p>
              Our web development agency offers a wide range of services including website design, front-end and back-end development, e-commerce solutions, content management systems (CMS), and custom web applications. We are dedicated to creating tailored solutions that meet the unique needs of our clients.
              </p>
            </div>
          </li>
          <li>
            <div
              data-bs-toggle="collapse"
              href="#faq2"
              className="collapsed question"
            >
              How long does it take to build a website? <i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </div>
            <div id="faq2" className="collapse" data-bs-parent=".faq-list">
              <p>
              The timeline for building a website depends on various factors, such as the complexity of the project, features required, and client feedback/response time. We work closely with our clients to establish realistic timelines and milestones to ensure timely delivery without compromising quality.
              </p>
            </div>
          </li>
          <li>
            <div
              data-bs-toggle="collapse"
              href="#faq3"
              className="collapsed question"
            >
              What technologies do you use in web development?<i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </div>
            <div id="faq3" className="collapse" data-bs-parent=".faq-list">
              <p>
              Our web development team is proficient in a variety of technologies, including but not limited to HTML5, CSS3, JavaScript, React, Angular, Vue.js for front-end development, and Node.js, Python, Ruby on Rails, PHP for back-end development. We always stay updated with the latest industry trends to provide cutting-edge solutions.
              We also have expertise in Wordpress and Shopify for CMS and e-commerce solutions.
              </p>
            </div>
          </li>
          <li>
            <div
              data-bs-toggle="collapse"
              href="#faq4"
              className="collapsed question"
            >
              Can you redesign an existing website?{" "}
              <i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </div>
            <div id="faq4" className="collapse" data-bs-parent=".faq-list">
              <p>
              Absolutely! We specialize in website redesign to give your online presence a fresh and modern look. Whether it's a visual revamp or an overhaul of the entire user experience, our team will work closely with you to understand your goals and bring your vision to life.
              </p>
            </div>
          </li>
          <li>
            <div
              data-bs-toggle="collapse"
              href="#faq5"
              className="collapsed question"
            >
              How do you ensure website security?{" "}
              <i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </div>
            <div id="faq5" className="collapse" data-bs-parent=".faq-list">
              <p>
              Ensuring the security of your website is a top priority for us. We implement best practices such as HTTPS, data encryption, secure coding techniques, and regular security audits. Additionally, we stay informed about the latest security threats to proactively address potential vulnerabilities.
              </p>
            </div>
          </li>
          <li>
            <div
              data-bs-toggle="collapse"
              href="#faq6"
              className="collapsed question"
            >
              Do you provide maintenance and support services?{" "}
              <i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </div>
            <div id="faq6" className="collapse" data-bs-parent=".faq-list">
              <p>
              Yes, we offer ongoing maintenance and support services to keep your website running smoothly. This includes updates, security patches, bug fixes, and any necessary modifications. Our goal is to provide continuous support to ensure your website remains secure and up-to-date.
              </p>
            </div>
          </li>
          <li>
            <div
              data-bs-toggle="collapse"
              href="#faq7"
              className="collapsed question"
            >
              What is the typical cost of a web development project?{" "}
              <i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </div>
            <div id="faq7" className="collapse" data-bs-parent=".faq-list">
              <p>
              The cost of a web development project varies based on its complexity, features, and specific requirements. We provide custom quotes after thoroughly understanding your project needs. Our pricing is transparent, and we strive to offer competitive rates without compromising on quality.
              </p>
            </div>
          </li>
          <li>
            <div
              data-bs-toggle="collapse"
              href="#faq9"
              className="collapsed question"
            >
              How do I get started with your web development services?{" "}
              <i className="bi bi-chevron-down icon-show" />
              <i className="bi bi-chevron-up icon-close" />
            </div>
            <div id="faq9" className="collapse" data-bs-parent=".faq-list">
              <p>
              Getting started is easy! Simply reach out to us through our contact form, email, or phone. We'll schedule a consultation to discuss your project requirements, goals, and any specific features you have in mind. From there, we'll provide you with a tailored proposal and guide you through the entire development process.

Feel free to customize these answers based on the specific offerings and approach of your web development agency.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  )
}

export default FAQs
