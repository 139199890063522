import React, {useEffect} from 'react';
import logo from '../images/logo.png';

function Nav() {
    useEffect(() => {
        const navbarlinks = document.querySelectorAll('#navbar .scrollto');
    
        const navbarlinksActive = () => {
          let position = window.scrollY + 200;
          navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return;
            let section = document.querySelector(navbarlink.hash);
            if (!section) return;
            if (position >= section.offsetTop && position <= section.offsetTop + section.offsetHeight) {
              navbarlink.classList.add('active');
            } else {
              navbarlink.classList.remove('active');
            }
          });
        };
    
        window.addEventListener('load', navbarlinksActive);
        window.addEventListener('scroll', navbarlinksActive);
    
        return () => {
          window.removeEventListener('load', navbarlinksActive);
          window.removeEventListener('scroll', navbarlinksActive);
        };
      }, []);
    const handleMobileNavToggle = () => {
        const navbar = document.querySelector('#navbar');
        const mobileNavToggle = document.querySelector('.mobile-nav-toggle');
    
        navbar.classList.toggle('navbar-mobile');
        mobileNavToggle.classList.toggle('bi-list');
        mobileNavToggle.classList.toggle('bi-x');
      };

  return (
    <header id="header" className="fixed-top d-flex align-items-center">
    <div className="container d-flex align-items-center justify-content-between">
      <div className="logo">
        {/* <h1 className="text-light">
          <a href="index.html">
            <span>Ninestars</span>
          </a>
        </h1> */}
        {/* Uncomment below if you prefer to use an image logo */}
        <a href="/"><img src={logo} alt="" class="img-fluid"/></a>
      </div>
      <nav id="navbar" className="navbar">
        <ul>
          <li>
            <a className="nav-link scrollto active" href="#hero">
              Home
            </a>
          </li>
          <li>
            <a className="nav-link scrollto" href="#about">
              About Us
            </a>
          </li>
          <li>
            <a className="nav-link scrollto" href="#services">
              Services
            </a>
          </li>
          <li>
            <a className="nav-link scrollto" href="#portfolio">
              Portfolio
            </a>
          </li>
          <li>
            <a className="nav-link scrollto" href="#team">
              Team
            </a>
          </li>
          {/* <li className="dropdown">
            <a href="#">
              <span>Drop Down</span> <i className="bi bi-chevron-down" />
            </a>
            <ul>
              <li>
                <a href="#">Drop Down 1</a>
              </li>
              <li className="dropdown">
                <a href="#">
                  <span>Deep Drop Down</span>{" "}
                  <i className="bi bi-chevron-right" />
                </a>
                <ul>
                  <li>
                    <a href="#">Deep Drop Down 1</a>
                  </li>
                  <li>
                    <a href="#">Deep Drop Down 2</a>
                  </li>
                  <li>
                    <a href="#">Deep Drop Down 3</a>
                  </li>
                  <li>
                    <a href="#">Deep Drop Down 4</a>
                  </li>
                  <li>
                    <a href="#">Deep Drop Down 5</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="#">Drop Down 2</a>
              </li>
              <li>
                <a href="#">Drop Down 3</a>
              </li>
              <li>
                <a href="#">Drop Down 4</a>
              </li>
            </ul>
          </li> */}
          <li>
            <a className="nav-link scrollto" href="#contact">
              Contact
            </a>
          </li>
          <li>
            <a className="getstarted scrollto" href="#about">
              Get Started
            </a>
          </li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"
          onClick={handleMobileNavToggle}
        />
        

      </nav>
      {/* .navbar */}
    </div>
  </header>
  )
}

export default Nav
