import React from 'react'

function About() {
  return (
    <>
    <section id="about" className="about">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-5 d-flex align-items-center justify-content-center about-img">
            <img
              src="assets/img/about-img.svg"
              className="img-fluid"
              alt=""
              data-aos="zoom-in"
            />
          </div>
          <div className="col-lg-6 pt-5 pt-lg-0">
            <h3 data-aos="fade-up">About Us</h3>
            <p data-aos="fade-up" data-aos-delay={100}>
            At Growvive, we are a passionate team dedicated to shaping exceptional digital experiences. With a global footprint, we take pride in having served clients from India, USA, UK, and Canada. In our one-year journey, our commitment goes beyond borders as we thrive on translating diverse visions into impactful online realities.
            </p>
            <h3 style={{
              fontSize:"30px"
            }} data-aos="fade-up" data-aos-delay={100}
        
            >
              Our Process
            </h3>
            <div className="row">
              <div className="col-md-6" data-aos="fade-up" data-aos-delay={100}>
                <i className="bi bi-people-fill" />
                <h4>Meet and Understand</h4>
                <p>
                We initiate by meeting you to grasp the essence of your requirements, ensuring a tailored approach to your digital aspirations.
                </p>
              </div>
              <div className="col-md-6" data-aos="fade-up" data-aos-delay={200}>
                <i className="bi bi-graph-up" />
                <h4>Niche Exploration</h4>
                <p>
                Our process involves studying websites within your niche, allowing us to present a solution that aligns seamlessly with industry trends.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6" data-aos="fade-up" data-aos-delay={100}>
                <i className="bi bi-window-sidebar" />
                <h4>Creative Design Showcase</h4>
                <p>
                We bring your ideas to life through a creative design phase, presenting visuals that capture the essence of your brand for your feedback.
                </p>
              </div>
              <div className="col-md-6" data-aos="fade-up" data-aos-delay={200}>
                <i className="bi bi-globe-central-south-asia" />
                <h4>Web Development and Delivery</h4>
                <p>
                From concept to reality, we craft and refine your website, delivering a digital presence that meets and exceeds your expectations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default About
