import React from 'react'

function Services() {
  return (
    <section id="services" className="services section-bg">
    <div className="container" data-aos="fade-up">
      <div className="section-title">
        <h2>Services</h2>
        <p>Check out the great services we offer</p>
      </div>
      <div className="row">
      <div
          className="col-md-6 col-lg-3 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay={400}
        >
          <div className="icon-box">
            <div className="icon">
              <i className="bx bx-world" />
            </div>
            <h4 className="title">
              Web Development
            </h4>
            <p className="description">
              We build creative and customized websites for your business, agency or personal porfolio using the latest technologies and frameworks.
            </p>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-3 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay={100}
        >
          <div className="icon-box">
            <div className="icon">
              <i className="bx bxs-window-alt" />
            </div>
            <h4 className="title">
              UI/UX Design
            </h4>
            <p className="description">
              We create beautiful and intuitive user interfaces for your website or web application to enhance the user experience.
            </p>
          </div>
        </div>

        <div
          className="col-md-6 col-lg-3 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay={300}
        >
          <div className="icon-box">
            <div className="icon">
              <i className="bx bx-tachometer" />
            </div>
            <h4 className="title">
            SEO Management
            </h4>
            <p className="description">
              We optimize your website to improve your ranking on search engines and increase organic traffic.
            </p>
          </div>
        </div>
        <div
          className="col-md-6 col-lg-3 d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay={200}
        >
          <div className="icon-box">
            <div className="icon">
              <i className="bx bxs-cart-alt" />
            </div>
            <h4 className="title">
              E-Commerce Solutions
            </h4>
            <p className="description">
              We build e-commerce websites and applications with a focus on user experience and conversion optimization.
            
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Services
