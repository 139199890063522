import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import '../slider-css/slick-theme.css';

function TestimonialSlide({ testimonial, company, logo }) {
  const slideStyle = {
    padding: '10px',
    borderRadius: '10px',
    textAlign: 'center',
    color: 'black',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const testimonialStyle = {
    fontSize: '1.5em',
    marginBottom: '20px',
  };

  const companyStyle = {
    fontWeight: 'bold',
    marginBottom: '10px',
  };

  const logoStyle = {
    width: '80px', // Adjust the size as needed
    height: 'auto',
    marginBottom: '10px',
  };

  return (
    <div style={slideStyle}>
      <p style={testimonialStyle}>{testimonial}</p>
      <img src={logo} alt={company} style={logoStyle} />
      <p style={companyStyle}>{company}</p>
    </div>
  );
}


function Clients() {
  const customArrowStyle = {
    color:"red",
    border: 'none',
    cursor: 'pointer',
  };

  const CustomPrevArrow = (props) => (
    <button {...props} style={customArrowStyle}>
      Previous
    </button>
  );

  const CustomNextArrow = (props) => (
    <button {...props} style={customArrowStyle}>
      Next
    </button>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  const testimonials = [
    {
      testimonial: '"'+ 'The work was truly outstanding, exceeding expectations and delivered on time with commendable quality!! ' + '"',
      company: 'Spacz Cube Architects, India',
      logo: 'assets/img/clients/client-5.png',
    },
    {
      testimonial: '"'+ 'Kudos for the smooth collaboration and delivering a trustly stunning end result!!' + '"',
      company: 'VCSS Canada',
      logo: 'assets/img/clients/client-1.png'
    },
    
    {
      testimonial: '"'+ 'Website delivered by Growvive was pretty good. It was a nice doing business with them!!' + '"',
      company: 'International Gateway Limited',
      logo: 'assets/img/clients/client-2.png',
    },
    {
      testimonial: '"'+ 'Working with Growvive was very interesting and the inputs were very helpful in reaching for wider audience!!' + '"',
      company: 'Weiw.in',
      logo: 'assets/img/clients/client-4.png',
    },
    // Add more testimonials as needed
  ];
  
  return (
    <section id="clients" className="clients section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Testimonials</h2>
          <p>
            What our clients say about us
          </p>
        </div>
        <div
          className="clients-slider"
          data-aos="fade-up"
          data-aos-delay={100}
        >
        <Slider {...settings}>
        {testimonials.map((item, index) => (
              <div key={index}>
                <TestimonialSlide
                  testimonial={item.testimonial}
                  company={item.company}
                  logo={item.logo}
                />
              </div>
            ))}
          
        </Slider>
          {/* <div className='row'>
            <div className='col-lg-4 col-md-4 col-4 d-flex align-items-center justify-content-center'>
              <img src='assets/img/clients/client-1.png' className='img-fluid' alt='' width={120} height={120}/>
            </div>
            <div className='col-lg-4 col-md-4 col-4 d-flex align-items-center justify-content-center'>
              <img src='assets/img/clients/client-2.png' className='img-fluid' alt='' width={140} height={140}/>
            </div>
            <div className='col-lg-4 col-md-4 col-4 d-flex align-items-center justify-content-center'>
              <img src='assets/img/clients/client-3.png' className='img-fluid' alt='' width={150} height={150}/>
            </div>
          </div> */}
          {/* <div className='row mt-4'>
            <div className='col-lg-4 col-md-4 col-4 d-flex align-items-center justify-content-center'>
              <img src='assets/img/clients/client-4.png' className='img-fluid' alt='' width={150} height={150}/>
            </div>
            <div className='col-lg-4 col-md-4 col-4 d-flex align-items-center justify-content-center'>
              <img src='assets/img/clients/client-5.png' className='img-fluid' alt='' width={150} height={150}/>
            </div>
            <div className='col-lg-4 col-md-4 col-4 d-flex align-items-center justify-content-center'>
              <img src='assets/img/clients/client-6.png' className='img-fluid' alt='' width={70} height={70}/>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default Clients
